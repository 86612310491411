import React from "react";
import axios from "axios";

// Require Codemirror
// import "codemirror/lib/codemirror.js"
// import "codemirror/mode/xml/xml.js"
// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/align.min.js";
// import "froala-editor/js/plugins/code_beautifier.min.js";
// import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/table.min.js";
// import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/entities.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/save.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/plugins/code_view.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/emoticons.min.css";
import "froala-editor/css/plugins/image_manager.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/line_breaker.min.css";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/css/plugins/char_counter.min.css";
// import "froala-editor/css/plugins/video.min.css";

// Require Font Awesome.
import "font-awesome/css/font-awesome.css";

import FroalaEditor from "react-froala-wysiwyg";
import { Card, Button, Space, Tooltip, message } from "antd";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class CK extends React.Component {
  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: `<span>${(props.contentSlideText || " ").replace(/\n/g, "<br />")}</span>`,
    };
    this.handleModelChange = this.handleModelChange.bind(this);
  }

  async slide2Lecture() {
    try {
      let contentData = this.state.content || "<p>&nbsp;&nbsp;</p>";
      let { data } = await axios.post(`/api/utils/html2image`, {
        payload: contentData,
      });
      // console.log(
      //   "🚀 ~ file: CKEditor.js ~ line 85 ~ CK ~ slide2Lecture ~ result",
      //   `/${data.preview}`
      // );
      // console.log(
      //   "🚀 ~ file: CKEditor.js ~ line 85 ~ CK ~ slide2Lecture ~ contentData",
      //   contentData
      // );
      // let element = document.createElement("div");
      // debugger;
      // element.insertAdjacentHTML("beforeend", contentData);
      // document.body.appendChild(element);

      // let result = await html2canvas(element, {
      //   // width: 1200,
      //   // height: 1000,
      // });

      // let i2d = result.toDataURL();
      // document.body.removeChild(element);
      // let file = dataURLtoFile(i2d, "Contribute Cloud Slide.png");
      this.props.addSlide(
        `${process.env.REACT_APP_API_HOST || "https://app3.contribute.cloud"}${
          data.preview
        }`
      );
      // this.setState({ content: "" });
    } catch (error) {
      message.error(
        error.message || "Oops, Error while converting your content to image."
      );
    }
  }

  updateContent(newContent) {
    this.setState({
      content: newContent,
    });
  }

  onChange(evt) {
    const newContent = evt.editor.getData();
    this.setState({
      content: newContent,
    });
  }

  afterPaste(evt) {
    console.log("afterPaste event called with event info: ", evt);
  }

  contentChanged(htmlStr) {
    this.setState({
      content: htmlStr,
    });
  }
  handleModelChange(model) {
    this.setState({
      content: model,
    });
  }
  render() {
    const { contentChanged } = this;
    return (
      <Card className="gx-card">
        <FroalaEditor
          model={this.state.content}
          onModelChange={this.handleModelChange}
          config={{
            events: {
              "image.beforeUpload": function (files) {
                var editor = this;
                if (files.length) {
                  // Create a File Reader.
                  var reader = new FileReader();
                  // Set the reader to insert images when they are loaded.
                  reader.onload = function (e) {
                    var result = e.target.result;
                    editor.image.insert(result, null, null, editor.image.get());
                  };
                  // Read image as base64.
                  reader.readAsDataURL(files[0]);
                }
                editor.popups.hideAll();
                // Stop default upload chain.
                return false;
              },
            },
            // fullPage: true,
            attribution: false,
            charCounterCount: true,
            // codeMirror: true,
            // pluginsEnabled: [
            //   'fullscreen',
            //   'codeBeautifier',
            // ],
            key: "AV:4~?3xROKLJKYHROLDXDR@d2YYGR_Bc1A8@5@4:1B2D2F2F1?1?2A3@1C1",
          }}
        />

        <Space style={{ paddingTop: "1rem" }} direction="horizontal">
          <Button
            onClick={this.slide2Lecture.bind(this)}
            size={"large"}
            type="primary"
            icon={<SaveOutlined />}
          >
            Save Slide & clear Content
          </Button>

          <Button
            onClick={() => this.setState({ content: "" })}
            size={"large"}
            type="primary"
            icon={<CloseCircleOutlined />}
          >
            Clear Editor Content
          </Button>
        </Space>
      </Card>
    );
  }
}

export default CK;
